<template>
    <div>
        <el-form
            :model="formData"
            ref="formData"
            label-position="top"
        >
            <div class="page-group-box">
                <div class="page-group-title">基本信息</div>
                <el-row :gutter="24" class="MT20">
                    <el-col>
                        <span class="color-regular">当前状态: </span> 
                        <span :class="formData.status == 3 ? 'color-danger' : ''">{{status.get(formData.status)}}</span>
                        <span v-if="formData.status == 3 && formData.refusedReason" class="color-danger">(理由: {{formData.refusedReason}})</span>
                        {{ formData.status == 5 ? ',请重新提交审核' : '' }}
                    </el-col>
                </el-row>
                <el-row :gutter="24" class="MT10">
                    <el-col :xs="24" :sm="12" :xl="8">
                        <el-form-item prop="userName" label="收款联系人姓名:" :rules="[{required: true, message: '请输入收款联系人姓名'}]">
                            <el-input v-model="formData.userName" placeholder="请输入收款联系人姓名" size="medium" maxlength="10"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :xl="8">
                        <el-form-item prop="userTelephone" label="收款联系人手机:" :rules="[{required: true, message: '请输入收款联系人手机'},{ validator: this.$utils.validatePhone }]">
                            <el-input v-model="formData.userTelephone" placeholder="请输入收款联系人手机" size="medium"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="24">
                    <el-col :xs="24" :sm="12" :xl="8">
                        <el-form-item prop="userEmail" label="收款联系人邮箱:" :rules="[{required: true, message: '请输入收款联系人邮箱'},{ type: 'email', message: '请输入正确的邮箱地址' }]">
                            <el-input v-model="formData.userEmail" placeholder="请输入收款联系人邮箱" size="medium" maxlength="100"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :xl="8">
                        <el-form-item prop="address" label="通讯地址:" :rules="[{required: true, message: '请输入通讯地址'}]">    
                                <el-input v-model.trim="formData.address" size="medium" maxlength="100" placeholder="请输入通讯地址"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="24">
                    <el-col :xs="24" :sm="12" :xl="8">
                        <el-form-item prop="bankCardId" label="银行卡号:" :rules="[{required: true, message: '请输入银行卡号'}]">    
                            <el-input v-model.trim="formData.bankCardId" @blur="formData.bankCardId = formData.bankCardId.replaceAll(' ','')" size="medium" maxlength="100" placeholder="请输入银行卡号"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :xl="8">
                        <el-form-item prop="bankAddress" :rules="[{required: true, message: '请输入开户网点'}]">    
                            <span slot="label">开户网点: <small><div class="color-danger">开户网点即支行名称，请准确选择，否则可能影响结款</div></small></span>
                            <el-row :gutter="10">
                                <el-col :span="8">
                                    <el-cascader
                                        ref="cityCascader"
                                        v-model="city"
                                        :options="cityList"
                                        :props="{label:'name',children:'cityList',value:'id'}"
                                        size="medium"
                                        placeholder="省份/城市">
                                    </el-cascader>
                                </el-col>
                                <el-col :span="16">
                                    <!-- <el-input v-model.trim="formData.bankAddress" size="medium" maxlength="100" placeholder="输入关键字查询支行"></el-input> -->
                                    <el-select
                                        v-model="formData.bankAddress"
                                        filterable
                                        remote
                                        reserve-keyword
                                        placeholder="输入关键字查询支行"
                                        :remote-method="remoteBankAddress"
                                        :loading="bankLoading"
                                        size="medium"
                                        @change="bankAddressChange"
                                        @focus="remoteBankAddress()"
                                        clearable>
                                        <el-option
                                            v-for="(item,index) in bankAddressObj && bankAddressObj.record"
                                            :key="index"
                                            :label="item.lname"
                                            :value="item.lname">
                                        </el-option>
                                    </el-select>
                                </el-col>
                            </el-row>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="24">
                    <el-col :xs="24" :sm="12" :xl="8">
                        <el-form-item prop="bankAccount" label="账户名:" :rules="[{required: true, message: '请输入账户名'}]">    
                            <el-input v-model.trim="formData.bankAccount" size="medium" maxlength="100" placeholder="请输入账户名"></el-input>
                        </el-form-item>
                    </el-col>
                    <!-- <el-col :xs="24" :sm="12" :xl="8">
                        <el-form-item prop="bankId" label="银行:" :rules="[{required: true, message: '请选择银行'}]">
                            <el-select v-model="formData.bankId" size="medium" >
                                <el-option v-for="(item, index) in bankList" :key="index" :value="item.id" :label="item.bankName"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col> -->
                    <el-col :xs="24" :sm="12" :xl="8">
                        <el-form-item prop="accountType" label="账户性质:">
                            <el-select v-model="formData.accountType" size="medium" @change="accountTypeChange">
                                <el-option :value="1" label="企业"></el-option>
                                <!-- <el-option :value="2" label="个人"></el-option>   21372453--> 
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="24">
                    <template v-if="formData.accountType == 2">
                        <el-col :xs="24" :sm="12" :xl="8">
                            <el-form-item prop="accountIdName" :rules="[{required: true, message: '请输入证件类型'}]" :show-message="formData.accountType == 2 ? true : false">    
                                <span slot="label">证件类型: <small>如:身份证、护照等</small></span>
                                <el-input v-model.trim="formData.accountIdName" size="medium" maxlength="100" placeholder="请输入证件类型"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :xs="24" :sm="12" :xl="8">
                        <el-form-item prop="userID" label="证件号码:" :rules="[{required: true, message: '请输入证件号码'}]"> 
                            <el-input v-model.trim="formData.userID" size="medium" maxlength="100" placeholder="请输入证件号码"></el-input>
                        </el-form-item>
                    </el-col>
                    </template>
                    <el-col v-else :xs="24" :sm="12" :xl="8">
                        <el-form-item prop="userID" :rules="[{required: true, message: '请输入营业执照编号'}]" :show-message="formData.accountType == 1 ? true : false">
                            <span slot="label">营业执照编号: <small>请填写跟账户名对应的企业营业执照编号</small></span>
                            <el-input v-model.trim="formData.userID" size="medium" maxlength="100" placeholder="请输入营业执照编号"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="24">
                    <template v-if="formData.accountType == 2">
                        <el-col :xs="24" :sm="12" :xl="8">
                            <el-form-item prop="pic1" :rules="[{required: true, message: '请上传证件'}]" :show-message="formData.pic1 ? false : true">
                                <span slot="label">上传证件: <small>如果是身份证,请上传人像面和国徽面</small></span>
                                <image-upload 
                                    :url.sync="formData.pic1" 
                                    :urlFull.sync="formData.pic1" 
                                >
                                </image-upload>
                            </el-form-item>
                        </el-col>
                        <el-col :xs="24" :sm="12" :xl="8">    
                            <el-form-item prop="pic2" label=" " class="hide-required-asterisk" :rules="[{required: true, message: '请上传证件'}]" :show-message="formData.pic2 ? false : true">
                                <image-upload 
                                    :url.sync="formData.pic2" 
                                    :urlFull.sync="formData.pic2" 
                                >
                                </image-upload>
                            </el-form-item>
                        </el-col>
                    </template>
                    <template v-else>
                        <el-col :xs="24" :sm="12" :xl="8">
                            <el-form-item prop="pic1" :rules="[{required: true, message: '请上传营业执照'}]" :show-message="formData.pic1 ? false : true">
                                <span slot="label">营业执照: <small>请上传营业执照副本扫描图,需要带有公章</small></span>
                                <image-upload 
                                    :url.sync="formData.pic1" 
                                    :urlFull.sync="formData.pic1" 
                                >
                                </image-upload>
                            </el-form-item>
                        </el-col>
                        <el-col :xs="24" :sm="12" :xl="8">
                            <el-form-item prop="otherPic" label="开户许可证/基本存款账户信息:" :rules="[{required: true, message: '请上传开户许可证'}]" :show-message="formData.otherPic ? false : true">
                                <image-upload 
                                    :url.sync="formData.otherPic" 
                                    :urlFull.sync="formData.otherPic" 
                                >
                                </image-upload>
                            </el-form-item>
                        </el-col>
                    </template>
                </el-row>
            </div>
        </el-form>
        <div class="page-group-box MT10">
            <el-button type="primary" @click="submitForm" :loading="btnLoading">提交</el-button>
        </div>
    </div>
</template>

<script>
// import {fetchBank} from '@/assets/js/commonApi';
import ImageUpload from '@/components/ImageUpload';
import {fetchAllCity} from '@/assets/js/commonApi'
export default {
    props:{
        formData:{
            type: Object,
            default: null
        }
    },
    components:{ImageUpload},
    data(){
        return{
            bankList:[],
            btnLoading:false,
            cityList: [],
            keyword: "",
            city: [],
            bankLoading: false,
            bankAddressObj: null
        }
    },
    computed:{
        status(){
            return new Map([
                [0,'未提交'],
                [1,'待审核'],
                [2,'已通过'],
                [3,'已拒绝'],
                [4,'已撤销'],
                [5,'已过期'],
            ])
        }
    },
    created(){
        // this.getBankList();
        this.getCityList();
    },
    methods:{
        //获取所有银行
        // getBankList(){
        //     fetchBank().then(res => {
        //         const {state, result} = res.data;
        //         if(state == 1){
        //             this.bankList = result;
        //         }
        //     })
        // },
        //获取所有城市
        async getCityList(){
            const res = await fetchAllCity({}).catch(() => {});
            const{ state, result} = res.data;
            if( state == 1 ){
                this.cityList = result;
                if( this.formData.bankProvinceCode && this.formData.bankCityCode ){
                    this.city = [Number( this.formData.bankProvinceCode ) , Number( this.formData.bankCityCode )]
                }
            }
        },
        //搜索支行
        remoteBankAddress(query){
            const _nodes = this.$refs.cityCascader.getCheckedNodes()
            const _pathLabels = _nodes && _nodes.length > 0 && _nodes[0] ? _nodes[0].pathLabels : [];
            
            if( !query && this.bankAddressObj ) return;
            this.bankLoading = true;
            this.$request({
                url: "/bank/getBankLName",
                params: {
                    bankCard: this.formData.bankCardId,
                    province: _pathLabels.length  > 0 ? _pathLabels[0] : "",
                    city: _pathLabels.length  > 1 ? _pathLabels[1] : "",
                    keyword: query || ""
                },
                method: "post",
            })
                .then((res) => {
                    const { state, msg, result } = res.data;
                    if (state == 1 ) {
                        if( result.record ){
                            this.bankAddressObj = result
                        }else{
                            this.bankAddressObj.record = []
                        }
                    } else {
                        this.$message.error(msg || "操作失败,请重试");
                        this.bankAddressObj.record = []
                    }
                })
                .catch(() => {
                    this.$message.error("操作失败,请重试");
                })
                .finally(() => {
                    this.bankLoading = false;
                });
        },
        //选择支行
        bankAddressChange(e){
            this.formData.bankId = this.bankAddressObj.bankId || '';
            const _o = this.bankAddressObj.record.find(v => v.lname == e);
            this.formData.bankName = _o && _o.bank ? _o.bank : "";
            this.formData.bankCityCode = _o && _o.cityCode ? _o.cityCode : "";
            this.formData.bankProvinceCode = _o && _o.provinceCode ? _o.provinceCode : "";
        },
        //设置图片
        setAccountPic(){
            const _pic = [];
            if(this.formData.pic1) _pic.push(this.formData.pic1);
            if(this.formData.pic2) _pic.push(this.formData.pic2);

            this.formData.accountPic = _pic.toString(); 
        },
        accountTypeChange(){
            this.formData.pic1 = '';
            this.formData.pic2 = '';
            this.formData.otherPic = '';
            this.formData.userID = '';
        },
        //表单提交
        submitForm() {
            this.$refs["formData"].validate((valid) => {
                if (valid) {
                    const _msg = `提交审核后,请您耐心等待,我们会尽快进行审核\
                        <br/>审核期间，将<span class="color-danger">无法</span>修改众筹收款信息\
                        <br/>如果是申请修改，那么新的收款信息在通过审核之前，我们将沿用之前的收款信息`;
                    this.$confirm(_msg, '提交', {
                        type: 'warning',
                        // center: true,
                        dangerouslyUseHTMLString: true,
                        closeOnClickModal: false
                    }).then( () => {
                        const _url = "/raise/receivables/submit";
                        this.btnLoading = true;
                        this.setAccountPic();
                        this.$request({
                            url: _url,
                            data: {...this.formData},
                            method: "post",
                        })
                            .then((res) => {
                                const { state, msg } = res.data;
                                if (state == 1) {
                                    this.$message.success('提交成功');
                                    this.$emit('onUpdate');
                                } else {
                                    this.$message.error(msg || "操作失败,请重试");
                                }
                            })
                            .catch(() => {
                                this.$message.error("操作失败,请重试");
                            })
                            .finally(() => {
                                this.btnLoading = false;
                            });
                    }).catch( () => {})
                }else {
                    return false;
                }
            });
        },
    }
}
</script>

<style lang="scss" scoped>
.hide-required-asterisk{
    ::v-deep .el-form-item__label{
        &:before{
            content: '' !important;
        }
    }
}
</style>