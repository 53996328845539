<template>
    <div>
        <div class="page-group-box data-tips data-status">
            <span class="icon el-icon-warning color-warning"></span> 
            当前状态:<span class="color-danger">待审核</span>,当前无法修改收款信息,如有任何疑问请联系秀动客服 400-188-6980
        </div>
        <div class="page-group-box">
            <div class="page-group-title">基本信息</div>
            <!-- <div class="data-status MT20">当前状态:待审核,当前无法修改收款信息,如有任何疑问请联系秀动客服 400-188-6980</div> -->
            <el-row class="MT20">
                <el-col :xs="24" :xl="16">
                    <table class="info-table" width="100%">
                        <tr>
                            <th>收款联系人姓名</th>
                            <td>{{formData.userName}}</td>
                            <th>银行</th>
                            <td>{{formData.bank}}</td>
                        </tr>
                        <tr>
                            <th>收款人联系手机</th>
                            <td>{{formData.userTelephone}}</td>
                            <th>开户网点</th>
                            <td>{{formData.bankAddress}}</td>
                        </tr>
                        <tr>
                            <th>收款人联系邮箱</th>
                            <td>{{formData.userEmail}}</td>
                            <th>账户名</th>
                            <td>{{formData.bankAccount}}</td>
                        </tr>
                        <tr>
                            <th>通讯地址</th>
                            <td>{{formData.address}}</td>
                            <th>卡号</th>
                            <td>{{formData.bankCardId}}</td>
                        </tr>
                        <tr>
                            <th>账户性质</th>
                            <td colspan="3">{{formData.accountType == 1 ? '企业' : '个人'}}</td>
                        </tr>
                        <tr v-if="formData.accountType == 1">
                            <th>营业执照编号</th>
                            <td colspan="3">{{formData.userID}}</td>   
                        </tr>
                        <tr v-else>
                            <th>证件类型</th>
                            <td>{{formData.accountIdName}}</td>
                            <th>证件号码</th>
                            <td>{{formData.userID}}</td>
                        </tr>
                        <tr>
                            <th>{{formData.accountType == 1 ? '营业执照' : '证件'}}</th>
                            <td colspan="3">
                                <div v-if="formData.accountPic" class="img">
                                    <el-image v-for="(item,index) in formData.accountPic.split(',')" :key="index" :src="item" fit="cover" :preview-src-list="formData.accountPic.split(',')"></el-image>
                                </div>
                            </td>
                        </tr>
                        <tr v-if="formData.accountType == 1">
                            <th>开户许可证/<br/>基本存款账户信息</th>
                            <td colspan="3">
                                <div v-if="formData.otherPic" class="img">
                                    <el-image v-for="(item,index) in formData.otherPic.split(',')" :key="index" :src="item" fit="cover" :preview-src-list="formData.otherPic.split(',')"></el-image>
                                </div>
                            </td>
                        </tr>
                    </table>
                </el-col>
            </el-row>
        </div>
    </div>
</template>

<script>
export default {
    props:{
        formData:{
            type: Object,
            default: null
        }
    },
    data(){
        return{
        }
    },
    methods:{
    },
}
</script>


<style lang="scss" scoped>
.data-tips{
    line-height: 32px;
    padding: 12px 20px;
    margin-bottom: 10px;
}
.icon{
    font-size: 14px;
}
.data-status{
    font-weight: 500;
}
.img{
    .el-image{
        width: 100px;
        height: 100px;
        margin-right: 10px;
    }
}
th{
    width: 130px;
}
</style>